import { FirebaseApp } from 'firebase/app';
import { Auth } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';
import { Functions } from 'firebase/functions';
import { FirebasePerformance } from 'firebase/performance';
import { FirebaseStorage } from 'firebase/storage';

export interface EnvironmentConfig {
  firebaseConfig: any;
  environment: any;
  firebaseApp: FirebaseApp;
  performance: FirebasePerformance;
  auth: Auth;
  firestore: Firestore;
  functions: Functions;
  storage: FirebaseStorage;
}

export class ConfigService {
  private static instance: ConfigService;

  private constructor() {}

  private config: EnvironmentConfig;

  static getInstance() {
    if (!ConfigService.instance) {
      ConfigService.instance = new ConfigService();
    }
    return ConfigService.instance;
  }

  init(config: EnvironmentConfig) {
    this.config = config;
    if (!this.config.environment.production) {
      console.warn('dev config');
    }
  }

  getConfig() {
    return this.config;
  }
}
